<template>
  <div class="wrapper">
    <left-bar />
    <div class="content-page">
      <div>
        <div class="navbar-custom">
          <topBar />
        </div>
        <!-- Start Content-->
        <b-container fluid="lg">
          <router-view />
        </b-container>
      </div>
      <footerBar />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import leftBar from "@/components/leftBar.vue";
import topBar from "@/components/topBar.vue";
import footerBar from "@/components/footerBar.vue";

export default {
  name: "Home",
  components: {
    leftBar,
    topBar,
    footerBar,
  },
};
</script>
<style lang="scss" scoped>
@import "../css/sass_files";
// Topbar Height
$topbar-height: 70px;

// Leftbar width
$leftbar-width: 220px;
$grid-gutter-width: 24px;
$bg-topbar: #fff;
$shadow: 0 0 35px 0 rgba(154, 161, 171, 0.15);
// Wrapper
.wrapper {
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding: 0;
  margin: 0;
}

//Content Page
.content-page {
  min-height: 100vh;
  padding: $topbar-height 0px 0px;
  margin-left: $leftbar-width;
  overflow: hidden;
}

.navbar-custom {
  position: fixed;
  top: 0;
  right: 0;
  left: $leftbar-width;
  z-index: 1001;
  min-height: $topbar-height;
  padding: 0 $grid-gutter-width;
  background-color: $bg-topbar;
  box-shadow: $shadow;
}

@media (max-width: 1024px) {
  .navbar-custom {
    right: 0;
    left: 70px;

    .app-search {
      display: none;
    }
  }
}

@media only screen and (max-width: 1024px) {
  // Content Page
  .content-page {
    margin-left: 70px;
  }
}
@include media-breakpoint-down(sm) {
  .content-page {
    padding: 65px 0px 0px 0px;
    margin-left: 0 !important;
  }
  .navbar-custom {
    left: 0;
    padding: 0 10px;
    margin: -5px -10px 0;
  }
  .button-menu-mobile {
    &.disable-btn {
      display: inline-block;
    }
  }
  .nav-user {
    padding: 17px 5px 17px 57px !important;

    .account-position,
    .account-user-name {
      display: none;
    }
  }
}
</style>
