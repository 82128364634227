<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
import { bus } from "@/main";

export default {
  data() {
    return {
      error: "",
    };
  },
  mounted() {
    bus.$on("errorDisplayAlert", (graphQLErrors) => {
      if (graphQLErrors) {
        graphQLErrors.forEach(({ message, extensions }) => {
          if (extensions.code === "constraint-violation") {
            //constraint violation detected
            this.error = "Duplicate input field Value: " + message;
            this.showErrorAlert();
          } else {
            // general graphql error
            this.error = message;
            this.showErrorAlert();
          }
        });
      }
    });
  },
  methods: {
    showErrorAlert() {
      this.$bvModal.msgBoxOk(this.error, {
        title: "Error",
        size: "sm",
        buttonSize: "sm",
        okVariant: "danger",
        headerClass: "p-2 border-bottom-0",
        footerClass: "p-2 border-top-0",
        centered: true,
      });
    },
  },
};
</script>

<style lang="scss">
@import "css/icons.min.css";
@import "css/sass_files";

#app {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
  margin: 0;
  font-family: Nunito, sans-serif;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.5;
  color: #6c757d;
  text-align: left;
  background-color: #fafbfe;
}
body {
  padding: 0;
  margin: 0;
  font-family: "Nunito", sans-serif;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.5;
  color: #6c757d;
  text-align: left;
  background-color: #fafbfe;
}

body {
  overflow-x: hidden;
}

*,
::after,
::before {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

dl,
ol,
ul {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ol ul,
ul ol,
ul ul {
  margin-bottom: 0;
}
</style>
