import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import { setContext } from "apollo-link-context";
import ApolloClient from "apollo-client";
import { HttpLink } from "apollo-link-http";
import { InMemoryCache } from "apollo-cache-inmemory";
import VueApollo from "vue-apollo";
import Vuelidate from "vuelidate";
import VueLodash from "vue-lodash";
import lodash from "lodash";
import { onError } from "apollo-link-error";
export const bus = new Vue();

// Import the plugin here
import { Auth0Plugin } from "./auth";

Vue.config.productionTip = false;

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueApollo);
Vue.use(Vuelidate);
Vue.use(VueLodash, { name: "custom", lodash: lodash });

Vue.use(Auth0Plugin, {
  domain: process.env.VUE_APP_AUTH0_DOMAIN,
  clientId: process.env.VUE_APP_AUTH0_CLIENT_ID,
  onRedirectCallback: (appState) => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    );
  },
  audience: process.env.VUE_APP_AUTH0_AUDIENCE,
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) bus.$emit("errorDisplayAlert", graphQLErrors);
  if (networkError) console.log(`[Network error]: ${networkError}`);
});

// Create an http link:

const httpLink = new HttpLink({
  uri: process.env.VUE_APP_ROOT_GRAPHQL_URL,
});

// async fetch auth token
import { getInstance } from "./auth";
const authService = getInstance();

const authLink = setContext(async () => {
  const token = await authService.getTokenSilently();
  return {
    headers: {
      Authorization: `Bearer ${token}`,
      ...(store.state.currentRole !== "" && {
        "x-hasura-role": store.state.currentRole,
      }),
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(errorLink.concat(httpLink)),
  cache: new InMemoryCache({
    addTypename: true,
  }),
});

const apolloProvider = new VueApollo({
  defaultClient: client,
});
new Vue({
  router,
  store,
  apolloProvider,
  render: (h) => h(App),
}).$mount("#app");
