import Vue from "vue";
import VueRouter from "vue-router";
import Home from "@/views/Home.vue";
import { authGuard } from "@/auth";
import store from "@/store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    beforeEnter: authGuard,
    children: [
      {
        path: "/admin",
        name: "admin",
        component: () => import("@/components/admin/index"),
        children: [
          {
            path: "pilot",
            name: "pilot",
            component: () => import("@/components/admin/pilot/index"),
          },
          {
            path: "operator",
            name: "operator",
            component: () => import("@/components/admin/operator/index"),
            beforeEnter: (to, from, next) => {
              let allowedRoles = ["operator", "webadmin", "zoneadmin"];
              if (allowedRoles.includes(store.state.currentRole)) {
                next();
              } else {
                next("/admin/pilot");
              }
            },
          },
          {
            path: "pilotAdd",
            name: "pilotAdd",
            props: (route) => ({ pilotID: route.params.pilotID || null }),
            component: () =>
              import("@/components/admin/pilot/onboarding/index"),
          },
          {
            path: "pilotProfile/:pilotID",
            name: "pilotProfile",
            component: () => import("@/components/admin/pilot/profile"),
          },
          {
            path: "wing",
            name: "wing",
            component: () => import("@/components/admin/equipment/wing/index"),
          },
          {
            path: "wingAdd",
            name: "wingAdd",
            props: (route) => ({ pilotID: route.params.wingID || null }),
            component: () => import("@/components/admin/equipment/wing/add"),
          },
          {
            path: "wingProfile/:wingId",
            name: "wingProfile",
            component: () =>
              import("@/components/admin/equipment/wing/profile"),
          },
          {
            path: "reserve",
            name: "reserve",
            component: () =>
              import("@/components/admin/equipment/reserve/index"),
          },
          {
            path: "reserveProfile/:reserveID",
            name: "reserveProfile",
            component: () =>
              import("@/components/admin/equipment/reserve/profile"),
          },
          {
            path: "reserveProfile/:reserveID",
            name: "reserveProfile",
            component: () =>
              import("@/components/admin/equipment/reserve/profile"),
          },
          {
            path: "reserveAdd",
            name: "reserveAdd",
            component: () => import("@/components/admin/equipment/reserve/add"),
          },
          {
            path: "orders",
            name: "orders",
            component: () => import("@/components/admin/orders/list"),
            beforeEnter: (to, from, next) => {
              if (
                !["webadmin", "zoneadmin"].includes(store.state.currentRole)
              ) {
                next("/admin/operator");
              } else {
                next();
              }
            },
          },
          {
            path: "flights",
            name: "flights",
            component: () => import("@/components/admin/flights/index"),
          },
        ],
      },
    ],
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/login"),
  },
];

const router = new VueRouter({
  mode: "history", // enable history mode
  routes,
});

export default router;
