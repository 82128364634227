<template>
  <footer class="footer">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-6">
          © Flutterbuy Ecom Ventures Private Limited,
          {{ new Date().getFullYear() }}.
        </div>
        <div class="col-md-6">
          <p class="float-right">Version: {{ version }}</p>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
import {version} from '@/../package.json';
export default {
  name: "footerBar",
  data() {
    return {
      version: version
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../css/sass_files";
//
// footer.scss
//

.footer {
  position: absolute;
  right: 0;
  bottom: 0;
  bottom: auto; //sohail
  margin-top: 30px; //sohail
  left: $leftbar-width;
  padding: 19px 24px 20px;
  color: $gray-600;
  border-top: 1px solid rgba($gray-600, 0.2);

  .footer-links {
    a {
      margin-left: 1.5rem;
      color: $gray-600;
      transition: all 0.4s;
      &:hover {
        color: $gray-900;
      }
      &:first-of-type {
        margin-left: 0;
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  .footer {
    left: 0 !important;
    text-align: center;
  }
}

// Used for account authentication pages
.footer-alt {
  left: 0;
  text-align: center;
  border: none;
}

// Layout - Topnav
body[data-layout="topnav"] {
  .footer {
    left: 0 !important;
    padding: 19px 0 20px;
  }
  &[data-layout-mode="boxed"] {
    .footer {
      max-width: $boxed-layout-width;
    }
  }
}

body[data-layout-mode="boxed"] {
  .footer {
    max-width: calc(#{$boxed-layout-width} - #{$leftbar-width});
    margin: 0 auto;
    background-color: $body-bg;
    border: none;
    box-shadow: $shadow;
  }

  &[data-leftbar-compact-mode="condensed"] {
    .footer {
      max-width: calc(#{$boxed-layout-width} - 70px);
    }
  }
}

body[data-layout="detached"] {
  .footer {
    left: 0;

    .container-fluid {
      max-width: 100%;
      padding: 0;
    }
  }
}
</style>
