import gql from "graphql-tag";

// GET PILOTS QUERY
export const GET_PILOTS = gql`
  query GetAllPilots {
    pilots {
      pilot_id
      pilot_type
      weight
      active
      user {
        avatar
        name
        phone_no
      }
      cpy_stats_aggregate {
        aggregate {
          sum {
            total_flights
          }
        }
      }
      operator {
        operator_id
        company_name
        zone {
          id
          name
        }
      }
    }
  }
`;

// GET PILOT DATA FOR GROUPING OF OPERATORS IN PILOT ASSIGNMENT
export const GET_PILOTS_FOR_ASSIGNMENT = gql`
  query GetAllPilots {
    pilots {
      pilot_id
      pilot_type
      operator {
        operator_id
        company_name
        zone {
          id
        }
      }
      user {
        name
        phone_no
      }
      cpy_stats_aggregate {
        aggregate {
          sum {
            total_flights
            c
            p
            y
          }
        }
      }
    }
  }
`;

//GET PILOTS FOR PILOT LISTING FILTER
export const GET_PILOTS_FOR_LISTING_FILTER = gql`
  query getPilotsForListing {
    pilots {
      pilot_type
      operator {
        operator_id
        company_name
        zone {
          id
          name
        }
      }
    }
  }
`;

// GET  OPERATORS QUERY
export const GET_OPERATORS = gql`
  query GetOperators {
    operators {
      company_name
      operator_id
      zone {
        id
        name
      }
    }
  }
`;

// GET  OPERATORS QUERY FOR ADD_PILOT
export const GET_OPERATORS_ADD_PILOT = gql`
  query GetOperatorsforAddPilot {
    operators {
      company_name
      operator_id
      zone {
        id
      }
      user {
        phone_no
      }
    }
  }
`;

// GET ZONES QUERY
export const GET_ZONES = gql`
  query GetZonesList {
    zone {
      id
      name
    }
  }
`;

// GET PILOT DATA
export const GET_PILOT_DATA = gql`
  query getPilotData($pilot_id: uuid!) {
    pilots_by_pk(pilot_id: $pilot_id) {
      pilot_type
      user {
        name
        phone_no
        alternate_phone_no
        email
        avatar
      }
      operator {
        company_name
        zone {
          name
        }
      }
      pilot_license {
        license_no
      }
      pilot_insurance {
        issue_date
        expiry_date
      }
      flights {
        flight_id
        created_at
        flight_time
        flight_type
      }
      cpy_stats {
        flight_type
        total_flights
        c
        p
        y
      }
      cpy_stats_aggregate {
        aggregate {
          sum {
            total_flights
          }
        }
      }
    }
  }
`;

// GET PILOT DATA FOR UPDATE
export const GET_PILOT_DATA_UPDATE = gql`
  query getPilotData($pilot_id: uuid!) {
    pilots_by_pk(pilot_id: $pilot_id) {
      pilot_type
      proof_of_identity_no
      proof_of_identity_type
      weight
      address
      tandem_flying_hours
      insurance_id
      license_id
      bank_details_id
      user {
        name
        phone_no
        alternate_phone_no
        email
      }
      operator {
        operator_id
        company_name
        zone {
          id
          name
        }
        user {
          phone_no
        }
      }
      pilot_license {
        license_no
        issuing_authority
        issue_date
        first_issue_date
        expiry_date
      }
      pilot_insurance {
        issue_date
        expiry_date
        policy_no
        insurance_provider
      }
      bank_account {
        account_name
        account_no
        account_type
        bank_name
        ifsc
        pan
      }
    }
  }
`;

// GET PILOT TYPE QUERY
export const GET_PILOT_TYPE = gql`
  query getPilotType {
    pilot_type {
      value
    }
  }
`;

export const GET_PILOT_ORDER_LIST = gql`
  query getPilotOrders($pilot_id: uuid!) {
    wc_orders(where: { flights: { pilot: { pilot_id: { _eq: $pilot_id } } } }) {
      order_data
    }
  }
`;

// GET LOGGED IN USER ROLE QUERY
export const GET_LOGGED_IN_USER_ROLE = gql`
  query userByEmail($email: String!) {
    users(where: { email: { _eq: $email } }) {
      user_roles {
        role
      }
    }
  }
`;

// OPERATORS LIST
export const LIST_OPERATORS = gql`
  query {
    operators(order_by: { zone: { name: asc } }) {
      operator_id
      user {
        name
        avatar
      }
      company_name
      zone {
        id
        name
      }
      pilots {
        pilot_id
        cpy_stats_aggregate {
          aggregate {
            sum {
              total_flights
            }
          }
        }
      }
    }
  }
`;

// OPERATOR BY ID
export const GET_OPERATOR_BY_ID = gql`
  query operatorById($id: uuid!) {
    operators_by_pk(operator_id: $id) {
      user {
        name
        email
        phone_no
      }
      zone_id
      company_name
    }
  }
`;

// USER BY EMAIL AND ROLE
export const GET_USER_BY_EMAIL_ROLE = gql`
  query userByEmailRole($email: String!, $role: user_role_enum!) {
    users(
      where: {
        _and: [
          { email: { _eq: $email } }
          { user_roles: { role: { _eq: $role } } }
        ]
      }
    ) {
      id
      user_roles {
        role
      }
    }
  }
`;

//GET WINGS DATA
export const GET_WINGS = gql`
  query getWings {
    wings {
      id
      operator {
        operator_id
        company_name
        zone {
          id
          name
        }
      }
      serial_no
      operational_weight_min
      operational_weight_max
      equipment_model {
        id
        brand
        name
      }
    }
  }
`;

export const GET_WING_INFO = gql`
  query getWings($wing_id: Int!) {
    wings_by_pk(id: $wing_id) {
      id
      operator {
        operator_id
        company_name
        zone {
          id
          name
        }
        pilots {
          user {
            avatar
            name
          }
          cpy_stats {
            total_flights
            y
          }
        }
      }
      serial_no
      operational_weight_min
      operational_weight_max
      equipment_model {
        id
        brand
        name
      }
      last_inspected_by
      last_inspection_date
      wing_color
      year_mfg
      glider_weight
      en_certification
    }
  }
`;

// GET WING DATA BY PK
export const GET_WING_PK = gql`
  query getWings($id: Int!) {
    wings_by_pk(id: $id) {
      id
      operator {
        operator_id
        company_name
        zone {
          id
          name
        }
      }
      serial_no
      operational_weight_min
      operational_weight_max
      en_certification
      wing_color
      year_mfg
      glider_weight
      equipment_model {
        id
        brand
        name
      }
    }
  }
`;

//GET WING FLIGHT:
export const GET_WING_FLIGHTS = gql`
  query wingFlight($wing_id: Int!) {
    flights(
      where: { pilot: { operator: { wings: { id: { _eq: $wing_id } } } } }
    ) {
      flight_id
      flight_type
      flight_time
      created_at
    }
  }
`;
export const GET_RESERVE_FLIGHTS = gql`
  query reserveFlight($reserve_id: Int!) {
    flights(
      where: { pilot: { operator: { reserves: { id: { _eq: $reserve_id } } } } }
    ) {
      flight_id
      flight_type
      flight_time
      created_at
    }
  }
`;
//GET RESERVES DATA
export const GET_RESERVES = gql`
  query getReserves {
    reserves {
      id
      operator {
        operator_id
        company_name
        zone {
          id
          name
        }
      }
      serial_no
      max_load
      reserve_weight
      equipment_model {
        brand
        name
      }
    }
  }
`;

//GET RESERVE BY PK
export const GET_RESERVE_PK = gql`
  query getReserveByPk($id: Int!) {
    reserves_by_pk(id: $id) {
      certification
      last_packed_by
      last_packing_date
      max_load
      model_id
      operator_id
      reserve_weight
      serial_no
      year_mfg
      equipment_model {
        brand
        id
        name
      }
      operator {
        operator_id
        zone {
          id
          name
          operators {
            company_name
          }
        }
        pilots {
          user {
            name
            avatar
          }
        }
      }
    }
  }
`;
//GET EQUIPMENT BRANDS
export const GET_EQUIPMENT_BRANDS = gql`
  query getEquipmentBrands {
    equipment_brands {
      value
    }
  }
`;

//GET MODELS FOR WINGS
export const GET_EQUIPMENT_MODELS = gql`
  query getModels {
    equipment_models(where: { equipment_type: { _eq: wing } }) {
      id
      name
      brand
    }
  }
`;

//GET MODELS FOR RESERVE
export const GET_EQUIPMENT_MODELS_RESERVE = gql`
  query getModels {
    equipment_models(where: { equipment_type: { _eq: reserve } }) {
      id
      name
      brand
    }
  }
`;

//GET EN CERTIFICATES
export const GET_EN_CERTIFICATES = gql`
  query getEnCertificate {
    en_certification_type {
      value
    }
  }
`;

//GET ORDERS
export const GET_ORDERS = gql`
  query fetchOrders($from: String!, $to: String!) {
    fetchOrders(from: $from, to: $to) {
      orders
    }
  }
`;

//GET WC ORDERS
export const GET_WC_ORDERS = gql`
  query getWcOrders($orderID: Int!) {
    wc_orders(where: { order_id: { _eq: $orderID } }) {
      order_data
      order_id
      flights {
        passenger
        flight_time
        created_at
        flight_id
        pilot_id
        flight_type
      }
    }
  }
`;

//GET ZONE FOR WC ORDERS
export const GET_WC_ZONES = gql`
  query wc_zones {
    zone_wc_products {
      wc_product_id
      type
      zone {
        id
        name
      }
    }
  }
`;
//GET PILOT ASSIGNED ON DATE
export const GET_PILOT_ASSIGNED_ON_DATE = gql`
  query pilotAssignedOnDate($from: timestamptz!, $to: timestamptz!) {
    flights(
      where: {
        _and: [{ flight_time: { _lt: $to } }, { flight_time: { _gte: $from } }]
      }
    ) {
      pilot_id
      flight_time
      wc_order_id
    }
  }
`;

//GET FLIGHT LIST
export const GET_FLIGHT_LIST = gql`
  query flightList($from: timestamptz!, $to: timestamptz!) {
    flights_aggregate(
      where: {
        _and: [{ flight_time: { _gte: $from } }, { flight_time: { _lte: $to } }]
      }
    ) {
      aggregate {
        count
      }
    }
    flights(
      where: {
        _and: [{ flight_time: { _gte: $from } }, { flight_time: { _lte: $to } }]
      }
    ) {
      flight_id
      wc_order_id
      passenger
      flight_time
      flight_type
      wc_order {
        order_data
      }
      pilot {
        user {
          name
          phone_no
        }
        operator {
          company_name
          zone {
            name
          }
        }
      }
    }
  }
`;
