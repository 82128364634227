import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    currentRole: "" || localStorage.getItem("currentRole"),
  },
  mutations: {
    setCurrentRole(state, role) {
      state.currentRole = role;
      localStorage.setItem("currentRole", role);
    },
  },
  modules: {},
});
