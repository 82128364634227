<template>
  <!-- ========== Left Sidebar Start ========== -->
  <div class="left-side-menu">
    <!-- LOGO -->
    <router-link to="/" class="logo text-center text-white">
      <span><b>paraBooking</b>BMS</span>
    </router-link>
    <simplebar class="h-100">
      <div class="left-side-menu-container" data-simplebar>
        <!--- Sidemenu -->
        <ul
          id="menu-bar"
          class="metismenu side-nav"
          v-if="$auth.isAuthenticated"
        >
          <li class="side-nav-title side-nav-item">Navigation</li>

          <li
            class="side-nav-item"
            v-if="['webadmin', 'zoneadmin'].includes(this.currentRole)"
          >
            <router-link to="/admin/orders" class="side-nav-link">
              <i class="uil-apps"></i>
              <span>Orders</span>
            </router-link>
          </li>

          <li class="side-nav-item">
            <router-link to="/admin/flights" class="side-nav-link">
              <i class="uil-plane"></i>
              <span>Flights</span>
            </router-link>
          </li>

          <li class="side-nav-item" v-if="this.currentRole !== 'pilot'">
            <a id="equip" class="side-nav-link" @click="eListShow = !eListShow">
              <i class="uil-gold"></i>
              <span id="equipment">Equipments</span>
              <span
                :class="eListShow ? 'menu-arrow-reverse' : 'menu-arrow'"
              ></span>
            </a>
            <ul
              class="side-nav-second-level"
              aria-expanded="false"
              v-if="eListShow"
            >
              <li class="side-nav-item">
                <router-link to="/admin/wing" class="side-nav-link">
                  Wings
                </router-link>
              </li>
              <li class="side-nav-item">
                <router-link to="/admin/reserve" class="side-nav-link">
                  Reserves
                </router-link>
              </li>
            </ul>
          </li>

          <li class="side-nav-item">
            <router-link to="/admin/pilot" class="side-nav-link">
              <i class="uil-users-alt"></i>
              <span>Pilots</span>
            </router-link>
          </li>

          <li
            class="side-nav-item"
            v-if="
              ['webadmin', 'zoneadmin', 'operator'].includes(this.currentRole)
            "
          >
            <router-link to="/admin/operator" class="side-nav-link">
              <i class="uil-store"></i>
              <span>Operators</span>
            </router-link>
          </li>
        </ul>
        <!-- End Sidebar -->

        <div class="clearfix"></div>
      </div>
      <!-- Sidebar -left -->
    </simplebar>
  </div>
  <!-- Left Sidebar End -->
</template>

<script>
import EventBus from "../eventbus";
import simplebar from "simplebar-vue";
import { mapState } from "vuex";

export default {
  components: { simplebar },
  name: "leftBar",
  data() {
    return {
      showList: false,
      eListShow: false,
      pilotShow: false,
      opListShow: false,
    };
  },
  computed: {
    ...mapState(["currentRole"]),
  },
  mounted() {
    // document.body.setAttribute('data-leftbar-compact-mode', 'condensed')
    EventBus.$on("showLeftBar", () => {
      document.body.classList.toggle("sidebar-enable");
    });
    window.onresize = () => {
      if (window.innerWidth > 1024.98) {
        document.body.classList.remove("sidebar-enable");
      }
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../css/sass_files";
//
// menu.scss
//
ul {
  padding-left: 0;
}
li {
  list-style: none;
}

// Wrapper
.wrapper {
  width: 100%;
  height: 100%;
  overflow: hidden;

  // Logo
  .logo {
    position: fixed;
    top: 0;
    display: block;
    width: $leftbar-width;
    line-height: $topbar-height;
    background: #ffffff0d;
    font-size: larger;
    &:hover,
    &:focus,
    &:active {
      text-decoration: none;
    }

    span.logo-lg {
      display: block;
    }
    span.logo-sm {
      display: none;
    }
  }

  // Left Side-menu (Default dark)
  .left-side-menu {
    position: fixed;
    top: 0;
    bottom: 0;
    z-index: 10;
    width: $leftbar-width;
    padding-top: $topbar-height;
    background: $bg-leftbar-dark;
    box-shadow: $shadow;
    transition: all 0.2s ease-out;

    // Side-nav
    .side-nav {
      .side-nav-link {
        position: relative;
        display: block;
        padding: 10px 30px;
        font-size: 0.9375rem;
        color: $menu-item;
        color: $sidebar-dark-menu-item;
        width: 100%;
        transition: all 0.4s;
        &:hover,
        &:focus,
        &:active {
          color: $menu-item-hover;
          text-decoration: none;
        }

        a {
          color: #cedce4;
        }

        span {
          vertical-align: middle;
        }

        i {
          display: inline-block;
          width: 20px;
          margin: 0 10px 0 0;
          font-size: 1.1rem;
          line-height: 1.0625rem;
          vertical-align: middle;
        }
      }
      .router-link-active {
        color: $menu-item-hover !important;
        background: #ffffff24;
      }

      .menu-arrow,
      .menu-arrow-reverse {
        position: absolute;
        right: 20px;
        display: inline-block;
        font-family: "Material Design Icons";
        font-size: 1.1rem;
        line-height: 1.5rem;
        transition: transform 0.15s;
        transform: translate(0, 0);
        text-rendering: auto;
        &::before {
          content: "\F142";
        }
      }

      .menu-arrow-reverse {
        transform: rotate(90deg);
      }

      .badge {
        margin-top: 3px;
      }

      .side-nav-item {
        &:active {
          > a {
            .menu-arrow {
              transform: rotate(90deg);
            }
          }
        }
      }

      .side-nav-title {
        padding: 12px 30px;
        font-size: 0.6875rem;
        font-weight: $font-weight-bold;
        color: $menu-item;
        text-transform: uppercase;
        letter-spacing: 0.05em;
        pointer-events: none;
        cursor: default;
      }

      .side-nav-second-level li a,
      .side-nav-third-level li a,
      .side-nav-forth-level li a {
        position: relative;
        display: inline-block;
        padding: 8px 25px 8px 60px;
        color: #8b9aa1;
        -webkit-transition: all 0.4s;
        transition: all 0.4s;
      }

      .mm-active {
        > a {
          color: $menu-item-active !important;
        }
      }
    }
  }
}

//Content Page
.content-page {
  min-height: 100vh;
  padding: $topbar-height 12px 65px;
  margin-left: $leftbar-width;
  overflow: hidden;
}

// Enlarge menu
@media only screen and (max-width: 1024px) and (min-width: 768px) {
  // min-height: 1600px;

  .wrapper {
    width: 100%;
    min-height: 1600px;

    // Side menu
    .left-side-menu {
      position: absolute;
      z-index: 5;
      width: 70px;
      padding-top: 0;
      padding-top: $topbar-height;
      &:hover,
      &:focus {
        width: $leftbar-width;
      }
      .left-side-menu-container {
        width: 70px;
        &:hover,
        &:focus {
          width: $leftbar-width;
        }

        .side-nav-second-level li a,
        .side-nav-third-level li a,
        .side-nav-forth-level li a {
          position: relative;
          display: block;
        }

        // Sidebar Menu
        .side-nav {
          .side-nav-title,
          .menu-arrow,
          .badge,
          .collapse.in,
          .mm-collapse {
            display: block;
          }
          .nav.collapse {
            height: inherit !important;
          }

          .side-nav-item {
            position: relative;
            white-space: nowrap;

            .side-nav-link {
              min-height: 56px;
              padding: 15px 20px;
              transition: none;

              &:hover,
              &:active,
              &:focus {
                color: $menu-item-hover;
                text-decoration: none;
              }

              i {
                margin-right: 20px;
                margin-left: 6px;
                font-size: 1.125rem;
              }

              span {
                /*display: none;*/
                padding-left: 10px;
              }
            }

            &:hover {
              .side-nav-link {
                position: relative;
                width: 220px;
                color: $white;
                background: $bg-leftbar;

                span {
                  display: inline;
                }
              }

              > ul {
                position: absolute;
                left: 70px;
                z-index: 9999;
                display: block !important;
                width: 190px;
                height: auto !important;
                padding: 5px 0;
                background: $bg-leftbar;
                a {
                  position: relative;
                  width: 190px;
                  padding: 8px 20px;
                  &:hover {
                    color: $menu-item-hover;
                  }
                }

                ul {
                  top: 0;
                  left: 190px;
                }
              }
            }
          }
        }
      }

      .logo {
        z-index: 1;
        width: 70px;
        background: $bg-leftbar;
      }
    }

    .metismenu {
      .has-arrow {
        &::after {
          display: none;
        }
      }
    }

    // Help Box
    .help-box {
      display: none;
    }

    // Content Page
    .content-page {
      margin-left: 70px;
    }

    //Navbar & Footer
    .navbar-custom,
    .footer {
      left: 70px;
    }
  }

  .logo {
    span.logo-lg {
      display: none;
    }
    span.logo-sm {
      display: block;
      line-height: 70px;
      color: $primary;
    }
  }
}

@include media-breakpoint-down(sm) {
  .left-side-menu {
    z-index: 10 !important;
    display: none;
    box-shadow: $shadow;
  }
  .sidebar-enable {
    .left-side-menu {
      display: block;
    }
  }
  .navbar-nav.navbar-right {
    float: right;
  }
  .content-page {
    padding: 65px 10px 65px;
    margin-left: 0 !important;
  }
  body[data-leftbar-compact-mode="condensed"] {
    .left-side-menu {
      margin-left: -70px;
    }
  }
  .logo {
    span.logo-lg {
      display: block;
    }
    span.logo-sm {
      display: none;
    }
  }
}

.button-menu-mobile {
  .lines {
    position: relative;
    display: block;
    width: 18px;
    height: 16px;
    margin-top: 26px;
    margin-left: 10px;
    transition: all 0.5s ease;
  }
  span {
    display: block;
    width: 100%;
    height: 2px;
    margin-bottom: 5px;
    background-color: rgba($white, 0.8);
    transition: transform 0.5s ease;

    &:nth-of-type(2) {
      width: 24px;
    }
  }

  &.disable-btn {
    display: none;
  }
}
</style>
